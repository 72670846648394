
















import { Component, Prop, Vue } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { AuditLog } from '@/store/modules/audit-log/types/audit-log.types';
import AuditLogList from './AuditLogList.vue';

@Component({
  components: { BasePaginatorHoc }
})
export default class PaginatedAuditLogs extends mixins(PaginationMixin) {
  @Prop() public auditLogs!: AuditLog;
  @Prop() public totalCount!: number;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';
  @Prop() public isDownload!: boolean | null;

  get AuditLogList() {
    return AuditLogList;
  }
}
