












































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { get as _get } from 'lodash';
import { AuditLog } from '@/store/modules/audit-log/types/audit-log.types';
import dayjs from 'dayjs';
import { PaymentStatusTypes } from '../../../store/types/general.types';

@Component({})
export default class AuditLogModal extends Vue {
  @Prop() public auditLogId!: number;
  @Prop() public items!: AuditLog[];

  get AuditLog() {
    return this.items.find((res) => res.id === this.auditLogId);
  }

  public toUpperCase(str?: string) {
    if (!str) {
      return 'N/A';
    }
    return str.charAt(0).toUpperCase() + str.slice(1) + ' Card';
  }

  get getBillingAddress() {
    if (!this.AuditLog?.transaction_status.billingAddress) {
      return 'N/A';
    }
    return this.AuditLog?.transaction_status.billingAddress;
  }

  get getCardHolder() {
    if (!this.AuditLog?.txnResp) {
      return 'N/A';
    }
    return _get(this.AuditLog?.txnResp, 'CardDetails.CardHolderName') || 'N/A';
  }

  get getPaymentMethod() {
    if (!this.AuditLog?.txnResp) {
      return 'N/A';
    }
    return _get(this.AuditLog?.txnResp, 'CardDetails.SubType');
  }

  get getCardNumber() {
    if (!this.AuditLog?.txnResp) {
      return 'N/A';
    }
    const cardNumber = _get(this.AuditLog?.txnResp, 'CardDetails');
    const cardType = _get(this.AuditLog?.txnResp, 'CardType');
    const maskedNumber = `${cardType} - XXXXXXXXXXXXX${cardNumber.MaskedCardNumber.substr(
      cardNumber.MaskedCardNumber.length - 3
    )}`;
    return maskedNumber;
  }

  public formatDate(date: Date) {
    const result = dayjs(date).format('D MMMM YYYY h:mm A');

    return result === 'Invalid Date' ? 'N/A' : result;
  }

  public getTransactionStatus(paymentStatus: PaymentStatusTypes) {
    return paymentStatus === PaymentStatusTypes.SUCCESS
      ? 'Successful'
      : paymentStatus === PaymentStatusTypes.FAILED
      ? 'Failed'
      : 'Pending';
  }
}
