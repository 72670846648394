
















































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { FilteredAuditPayload } from '@/store/modules/audit-log/types/audit-log.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { PaymentStatusTypes } from '../../../store/types/general.types';

@Component({
  components: {}
})
export default class SearchBoxx extends Vue {
  @Prop(Object) public params!: FilteredAuditPayload & { dates: [] };

  @Watch('params', { deep: true })
  @Debounce(500)
  public onChange() {
    this.$emit('input', this.params);
  }

  get statusesOptions() {
    return [
      { label: 'All Transactions Status ', value: '' },
      { label: 'Successful', value: PaymentStatusTypes.SUCCESS },
      { label: 'Failed', value: PaymentStatusTypes.FAILED }
    ];
  }
  async handleDatePickerToggle() {
    await this.$nextTick();
    const scroll = () =>
      ((this.$refs?.datepicker as Vue)?.$refs
        ?.dropdown as Vue)?.$el?.scrollIntoView();
    scroll();
  }

  public resetFilter() {
    return this.$emit('resetFilter');
  }
}
